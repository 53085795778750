const constants = {
  SUBMIT_REQUEST_QUOTE_APP_LOADING: 'SUBMIT_REQUEST_QUOTE_APP_LOADING',
  SUBMIT_REQUEST_QUOTE_APP_SUCCESS: 'SUBMIT_REQUEST_QUOTE_APP_SUCCESS',
  SUBMIT_REQUEST_QUOTE_APP_ERROR: 'SUBMIT_REQUEST_QUOTE_APP_ERROR',
  SUBMIT_REQUEST_QUOTE_APP_RESET: 'SUBMIT_REQUEST_QUOTE_APP_RESET'
}

const initalState = {
  isLoading: false,
  error: null,
  isSubmitted: false
}

export default function requestQuoteReducer(state = initalState, action) {
  switch (action.type) {
    case constants.SUBMIT_REQUEST_QUOTE_APP_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case constants.SUBMIT_REQUEST_QUOTE_APP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSubmitted: true
      }
    case constants.SUBMIT_REQUEST_QUOTE_APP_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case constants.SUBMIT_REQUEST_QUOTE_APP_RESET:
      return {
        ...state,
        isSubmitted: false
      }
    default:
      return state
  }
}
