const constants = {
  SUBMIT_WISHLIST_APP_LOADING: "SUBMIT_WISHLIST_APP_LOADING",
  SUBMIT_WISHLIST_APP_SUCCESS: "SUBMIT_WISHLIST_APP_SUCCESS",
  SUBMIT_WISHLIST_APP_ERROR: "SUBMIT_WISHLIST_APP_ERROR"
}

const initalState = {
  isLoading: false,
  error: null,
  isSubmitted: false
}


export default function wishlistReducer(state = initalState, action) {
  switch (action.type) {
    case constants.SUBMIT_WISHLIST_APP_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case constants.SUBMIT_WISHLIST_APP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSubmitted: true
      }
    case constants.SUBMIT_WISHLIST_APP_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    default:
      return state
  }
}
