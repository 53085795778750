import { inputDateDefault } from "@utils"

// ** Initial State
const initialState = {
  data: [],
  counts: {
    left: {},
    right: {}
  },
  all_data: {},
  url: "",
  params: {},
  loading: true,
  chart: {
    left: {
      start_date: inputDateDefault(3),
      end_date: inputDateDefault(0),
      loading: false
    },
    right: {
      start_date: inputDateDefault(3),
      end_date: inputDateDefault(0),
      referral_loading: null
    },

    isReferral: null
  },
  selected_agent: null,
  detail: {
    estate_agent_name: "",
    email_main: "",
    tel_main: "",
    hot_count: 0,
    completion_count: 0,
    all_cases_not_exchanged: 0,
    exchanged_count: 0,
    not_completed_count: 0,
    this_month_quotes: 0,
    instructed_count: 0,
    pending_count: 0,
    dead_count: 0,
    month_names: {
      left: [],
      right: []
    }
  }
}

const agentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_AGENTS":
      return {
        ...state,
        data: action.data,
        counts: action.counts,
        loading: false
      }

    case "GET_ALL_AGENTS":
      return {
        ...state,
        all_data: action.val,
        params: action.params
      }
    case "CLEAR_AGENTS":
      return initialState
    case "AGENTS_LOADING":
      return {
        ...state,
        loading: true
      }
    case "AGENTS_LOADING_STOP":
      return {
        ...state,
        loading: false
      }
    case "CHART_LOADING":
      return {
        ...state,
        chart: { ...state.chart, left: { ...state.chart.left, loading: true } }
      }
    case "CHART_LOADING_STOP":
      return {
        ...state,
        chart: {
          ...state.chart,
          left: { ...state.chart.left, loading: false }
        }
      }

    case "REFERRAL_LOADING":
      return {
        ...state,
        chart: {
          ...state.chart,
          right: { ...state.chart.right, referral_loading: true }
        }
      }
    case "REFERRAL_LOADING_STOP":
      return {
        ...state,
        chart: {
          ...state.chart,
          right: { ...state.chart.right, referral_loading: false },
          isReferral: false
        }
      }

    case "CHART_START_DATE_UPDATE":
      return {
        ...state,
        chart: {
          ...state.chart,
          left: { ...state.chart.left, start_date: action.val }
        }
      }
    case "CHART_END_DATE_UPDATE":
      return {
        ...state,
        chart: {
          ...state.chart,
          left: { ...state.chart.left, end_date: action.val }
        }
      }
    case "REFERRAL_START_DATE_UPDATE":
      return {
        ...state,
        chart: {
          ...state.chart,
          isReferral: true,
          right: { ...state.chart.right, start_date: action.val }
        }
      }
    case "REFERRAL_END_DATE_UPDATE":
      return {
        ...state,
        chart: {
          ...state.chart,
          isReferral: true,
          right: { ...state.chart.right, end_date: action.val }
        }
      }
    case "UPDATE_SELECTED_AGENT":
      return {
        ...state,
        selected_agent: action.val
      }
    case "UPDATE_AGENT_DETAIL":
      return {
        ...state,
        detail: action.val,
        loading: false
      }
    case "UPDATE_MONTHS":
      return {
        ...state,
        detail: {
          ...state.detail,
          month_names: {
            ...state.detail.month_names,
            left: [...action.val.month_names.left],
            right: [...action.val.month_names.right]
          }
        }
      }
    case 'GET_AGENTS_AFTER_REFRESH':
      console.log('STATE DETAİL ', action.val1)

      return {
        ...state,
        details: { ...action.val }
      }
    case "GET_AGENTS_RIGHT":


      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.val1,
          referral_counts: action.val.referral_counts,
          referral_sum: action.val.referral_sum,
          month_names: {
            ...state.detail.month_names,
            right: [...action.val.month_names.right]
          }
        },
        loading: false
      }
    case "GET_AGENTS_LEFT":
      return {
        ...state,

        detail: {
          ...state.detail,
          avg_monthly: action.val.avg_monthly,
          counts_monthly: action.val.counts_monthly,
          sum_monthly: action.val.sum_monthly,
          month_names: {
            ...state.detail.month_names,
            left: [...action.val.month_names.left]
          }
        },
        loading: false
      }
    case "REFERRAL_START":
      return {
        ...state,
        chart: { ...state.chart, isReferral: true }
      }
    default:
      return state
  }
}

export default agentsReducer
