const initialState = {
  fetching: false,
  fetched: false,
  error: false
}
const validPhoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SUBMIT_VALIDPHONE_PENDING":
      return {
        ...state,
        fetching: true
      }
    case "SUBMIT_VALIDPHONE_DONE":
      return {
        ...state,
        fetching: false,
        fetched: true,
        payload: action.payload
      }
    case "SUBMIT_VALIDPHONE_ERROR":
      return {
        ...state,
        fetching: false,
        error: true
      }
    default:
      return state
  }
}
export default validPhoneReducer
