// ** Initial State
const initialState = {
  data: [],
  allCasesCount: 0,
  params: {},
  url: '',
  loading: true
}

const historiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_HISTORIES':
      return {
        ...state,
        data: action.data,
        allCasesCount: action.allCasesCount,
        params: action.params,
        url: action.url,
        loading: false
      }
    case 'CLEAR_HISTORIES':
      return initialState
    case 'HISTORIES_LOADING':
      return {
        ...state,
        loading: true
      }
    default:
      return state
  }
}

export default historiesReducer
