/* eslint-disable multiline-ternary */
// ** Initial State
const initialState = {
  isDuplicate: false,
  isReview: false,
  qc_case_ref: '',
  colored: {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false
  },
  quickNote: '',

  matterType: '',
  soldOrNotSold: '',
  selfInstructOrQuoteOnly: '',
  takenSeller: '',
  freeValuation: '',

  referralAgent: '',
  estateAgent: '',
  referralAgentCorr: '',
  negotiator: {
    value: '',
    isRequired: false
  },
  saleFee: '',
  defaultSaleFee: '',
  purchaseFee: '',
  defaultPurchaseFee: '',

  clientTitle: '',
  clientFirstName: '',
  clientMiddleName: '',
  clientLastName: '',
  clientMobile: '',
  clientMobileError: false,
  clientEmail: '',
  clientBirthday: '',
  clientAddressEntry: 'clientAutoAddressEntry',
  clientAddressList: [],
  clientAutoPostcode: '',
  clientAutoAddressSelectBox: '',
  clientPostcode: '',
  clientAddressLine1: '',
  clientAddressLine2: '',
  clientAddressLine3: '',

  clientIsSPAlone: '',
  client2_on: 'No',
  client2Title: '',
  client2FirstName: '',
  client2MiddleName: '',
  client2LastName: '',
  client2Mobile: '',
  client2MobileError: false,
  client2Email: '',
  client2Birthday: '',
  client2AddressIdentical: 'Yes',
  client2AddressEntry: 'client2AutoAddressEntry',
  client2AddressList: [],
  client2AutoPostcode: '',
  client2AutoAddressSelectBox: '',
  client2Postcode: '',
  client2AddressLine1: '',
  client2AddressLine2: '',
  client2AddressLine3: '',

  saleProperty: {
    salePropertyPrice: undefined,
    saleOverPriceCheck: false,
    saleOverPriceDetail: '',
    saleType: '',
    saleLenderFee: '',
    saleLeaseholdFee: '147',
    saleFtFee: 0,
    saleNoMoveNoCostsFee: 0,
    saleCompanyNotList: false,
    saleCompanyName: '',
    saleCompanySelectBox: '',
    saleLandlordNotList: false,
    saleLandlordName: '',
    saleLandlordSelectBox: '',
    saleMortgage: '',
    saleLenderNotList: false,
    saleLenderName: '',
    saleLenderSelectBox: '',

    propertyType: '',
    bedrooms: '',
    bathrooms: '',
    finishQuality: '',
    spaceType: '',
    parkingSpace: '',

    clientAddressIdentical: '',
    saleAddressEntry: 'saleAutoAddressEntry',
    saleAddressList: [],
    saleAutoPostcode: '',
    saleAutoAddressSelectBox: '',
    salePostcode: '',
    saleAddressLine1: '',
    saleAddressLine2: '',
    saleAddressLine3: ''
  },
  purchaseProperty: {
    purchasePropertyPrice: undefined,
    purchaseOverPriceCheck: false,
    purchaseOverPriceDetail: '',
    purchaseType: '',
    purchaseLenderFee: '',
    purchaseLeaseholdFee: '147',
    purchaseFtFee: 0,
    purchaseNoMoveNoCostsFee: 0,
    purchaseMortgage: '',
    purchaseLenderNotList: false,
    purchaseLenderName: '',
    purchaseLenderSelectBox: '',
    purchaseStampDutyQ: '',
    purchaseFirstTimeBuyer: '',
    purchaseBuying: '',

    purchaseAddressCheck: '',
    purchaseAddressEntry: 'purchaseAutoAddressEntry',
    purchaseAddressList: [],
    purchaseAutoPostcode: '',
    purchaseAutoAddressSelectBox: '',
    purchasePostcode: '',
    purchaseAddressLine1: '',
    purchaseAddressLine2: '',
    purchaseAddressLine3: ''
  },
  estateAgent: {
    data: []
  },
  resendToClient: false,

  agree: false,
  no_move_no_costs: false,
  fast_track_to_exchange: false
}

const quoteFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'QUOTE_FORM_CLEAR':
      return initialState

    case 'UPDATE_IS_DUPLICATE':
      return { ...state, isDuplicate: action.val }
    case 'UPDATE_IS_REVIEW':
      return { ...state, isReview: action.val }

    case 'UPDATE_QC_CASE_REF':
      return { ...state, qc_case_ref: action.val }

    case 'UPDATE_QUICK_NOTE':
      return { ...state, quickNote: action.val }

    case 'UPDATE_MATTER_TYPE':
      return { ...state, matterType: action.val }
    case 'UPDATE_SOLD_OR_NOTSOLD_TYPE':
      return { ...state, soldOrNotSold: action.val }
    case 'UPDATE_SELFINSTRUCT_OR_QUOTE':
       return { ...state, selfInstructOrQuoteOnly: action.val }
    case 'UPDATE_TAKEN_SELLER':
      return { ...state, takenSeller: action.val }
    case 'UPDATE_FREE_VALUATION':
      return { ...state, freeValuation: action.val }
      

    case 'UPDATE_REFERRAL_AGENT':
      return { ...state, referralAgent: action.val, referralAgentCorr: action.valCorr }
    case 'UPDATE_ESTATE_AGENT':
      return { ...state, estateAgent: action.val }
    case 'UPDATE_NEGOTIATOR':
      return {
        ...state,
        negotiator: { value: action.val, isRequired: state.negotiator.isRequired }
      }
    case 'UPDATE_NEGOTIATOR_REQUIRED':
      return { ...state, negotiator: { value: state.negotiator.value, isRequired: action.val } }
    case 'UPDATE_SALE_FEE':
      return { ...state, saleFee: action.val }
    case 'UPDATE_DEFAULT_SALE_FEE':
      return { ...state, defaultSaleFee: action.val }
    case 'UPDATE_PURCHASE_FEE':
      return { ...state, purchaseFee: action.val }
    case 'UPDATE_DEFAULT_PURCHASE_FEE':
      return { ...state, defaultPurchaseFee: action.val }

    case 'UPDATE_COLORED_STEP1':
      return { ...state, colored: { ...state.colored, step1: action.val } }
    case 'UPDATE_COLORED_STEP2':
      return { ...state, colored: { ...state.colored, step2: action.val } }
    case 'UPDATE_COLORED_STEP3':
      return { ...state, colored: { ...state.colored, step3: action.val } }
    case 'UPDATE_COLORED_STEP4':
      return { ...state, colored: { ...state.colored, step4: action.val } }
    case 'UPDATE_COLORED_STEP5':
      return { ...state, colored: { ...state.colored, step5: action.val } }

    case 'UPDATE_CLIENT_TITLE':
      return { ...state, clientTitle: action.val }
    case 'UPDATE_CLIENT_FIRSTNAME':
      return { ...state, clientFirstName: action.val }
    case 'UPDATE_CLIENT_MIDDLENAME':
      return { ...state, clientMiddleName: action.val }
    case 'UPDATE_CLIENT_LASTNAME':
      return { ...state, clientLastName: action.val }
    case 'UPDATE_CLIENT_MOBILE':
      return { ...state, clientMobile: action.val }
    case 'UPDATE_CLIENT_MOBILE_ERROR':
      return { ...state, clientMobileError: action.val }
    case 'UPDATE_CLIENT_EMAIL':
      return { ...state, clientEmail: action.val }
    case 'UPDATE_CLIENT_BIRTHDAY':
      return { ...state, clientBirthday: action.val }
    case 'UPDATE_CLIENT_ADDRESS_ENTRY':
      return { ...state, clientAddressEntry: action.val }
    case 'UPDATE_CLIENT_ADDRESS_LIST':
      return { ...state, clientAddressList: action.val }
    case 'UPDATE_CLIENT_AUTO_POSTCODE':
      return { ...state, clientAutoPostcode: action.val }
    case 'UPDATE_CLIENT_AUTO_SELECT_ADDRESS':
      return { ...state, clientAutoAddressSelectBox: action.val }
    case 'UPDATE_CLIENT_POSTCODE':
      return { ...state, clientPostcode: action.val }
    case 'UPDATE_CLIENT_ADDRESS_LINE_1':
      return { ...state, clientAddressLine1: action.val }
    case 'UPDATE_CLIENT_ADDRESS_LINE_2':
      return { ...state, clientAddressLine2: action.val }
    case 'UPDATE_CLIENT_ADDRESS_LINE_3':
      return { ...state, clientAddressLine3: action.val }

    case 'UPDATE_CLIENT_IS_SP_ALONE':
      return { ...state, clientIsSPAlone: action.val }
    case 'UPDATE_CLIENT2_ON':
      return { ...state, client2_on: action.val }
    case 'UPDATE_CLIENT2_TITLE':
      return { ...state, client2Title: action.val }
    case 'UPDATE_CLIENT2_FIRSTNAME':
      return { ...state, client2FirstName: action.val }
    case 'UPDATE_CLIENT2_MIDDLENAME':
      return { ...state, client2MiddleName: action.val }
    case 'UPDATE_CLIENT2_LASTNAME':
      return { ...state, client2LastName: action.val }
    case 'UPDATE_CLIENT2_MOBILE':
      return { ...state, client2Mobile: action.val }
    case 'UPDATE_CLIENT2_MOBILE_ERROR':
      return { ...state, client2MobileError: action.val }
    case 'UPDATE_CLIENT2_EMAIL':
      return { ...state, client2Email: action.val }
    case 'UPDATE_CLIENT2_BIRTHDAY':
      return { ...state, client2Birthday: action.val }
    case 'UPDATE_CLIENT2_ADDRESS_ENTRY':
      return { ...state, client2AddressEntry: action.val }
    case 'UPDATE_CLIENT2_ADDRESS_LIST':
      return { ...state, client2AddressList: action.val }
    case 'UPDATE_CLIENT2_AUTO_POSTCODE':
      return { ...state, client2AutoPostcode: action.val }
    case 'UPDATE_CLIENT2_AUTO_SELECT_ADDRESS':
      return { ...state, client2AutoAddressSelectBox: action.val }
    case 'UPDATE_CLIENT2_POSTCODE':
      return { ...state, client2Postcode: action.val }
    case 'UPDATE_CLIENT2_ADDRESS_LINE_1':
      return { ...state, client2AddressLine1: action.val }
    case 'UPDATE_CLIENT2_ADDRESS_LINE_2':
      return { ...state, client2AddressLine2: action.val }
    case 'UPDATE_CLIENT2_ADDRESS_LINE_3':
      return { ...state, client2AddressLine3: action.val }
    case 'UPDATE_CLIENT2_ADDRESS_IDENTICAL':
      return { ...state, client2AddressIdentical: action.val }

    case 'UPDATE_SALE_PROPERTY_PRICE':
      return {
        ...state,
        saleProperty: {
          ...state.saleProperty,
          salePropertyPrice:
            String(action.val).split('').length <= 7 && +action.val <= 2000000
              ? action.val
              : state.purchaseProperty.purchasePropertyPrice
        }
      }
    case 'UPDATE_SALE_OVER_PRICE_CHECK':
      return { ...state, saleProperty: { ...state.saleProperty, saleOverPriceCheck: action.val } }
    case 'UPDATE_SALE_OVER_PRICE_DETAIL':
      return { ...state, saleProperty: { ...state.saleProperty, saleOverPriceDetail: action.val } }

    case 'UPDATE_SALE_TYPE':
      return { ...state, saleProperty: { ...state.saleProperty, saleType: action.val } }
    case 'UPDATE_SALE_COMPANY_NOT_LIST':
      return { ...state, saleProperty: { ...state.saleProperty, saleCompanyNotList: action.val } }
    case 'UPDATE_SALE_COMPANY_NAME':
      return { ...state, saleProperty: { ...state.saleProperty, saleCompanyName: action.val } }
    case 'UPDATE_SALE_COMPANY_SELECTBOX':
      return { ...state, saleProperty: { ...state.saleProperty, saleCompanySelectBox: action.val } }
    case 'UPDATE_SALE_LENDER_FEE':
      return { ...state, saleProperty: { ...state.saleProperty, saleLenderFee: action.val } }
    case 'UPDATE_SALE_LEASEHOLD_FEE':
      return { ...state, saleProperty: { ...state.saleProperty, saleLeaseholdFee: action.val } }
    case 'UPDATE_SALE_FT_FEE':
      return { ...state, saleProperty: { ...state.saleProperty, saleFtFee: action.val } }
    case 'UPDATE_SALE_NO_MOVE_NO_COSTS_FEE':
      return { ...state, saleProperty: { ...state.saleProperty, saleNoMoveNoCostsFee: action.val } }

    case 'UPDATE_SALE_LANDLORD_NOT_LIST':
      return { ...state, saleProperty: { ...state.saleProperty, saleLandlordNotList: action.val } }
    case 'UPDATE_SALE_LANDLORD_NAME':
      return { ...state, saleProperty: { ...state.saleProperty, saleLandlordName: action.val } }
    case 'UPDATE_SALE_LANDLORD_SELECTBOX':
      return {
        ...state,
        saleProperty: { ...state.saleProperty, saleLandlordSelectBox: action.val }
      }

    case 'UPDATE_SALE_MORTGAGE':
      return { ...state, saleProperty: { ...state.saleProperty, saleMortgage: action.val } }
    case 'UPDATE_SALE_LENDER_NOT_LIST':
      return { ...state, saleProperty: { ...state.saleProperty, saleLenderNotList: action.val } }
    case 'UPDATE_SALE_LENDER_NAME':
      return { ...state, saleProperty: { ...state.saleProperty, saleLenderName: action.val } }
    case 'UPDATE_SALE_LENDER_SELECTBOX':
      return { ...state, saleProperty: { ...state.saleProperty, saleLenderSelectBox: action.val } }

    case 'UPDATE_SALE_PROPERTY_TYPE':
      return { ...state, saleProperty: { ...state.saleProperty, propertyType: action.val } }
    case 'UPDATE_SALE_BEDROOMS':
      return { ...state, saleProperty: { ...state.saleProperty, bedrooms: action.val } }
    case 'UPDATE_SALE_BATHROOMS':
      return { ...state, saleProperty: { ...state.saleProperty, bathrooms: action.val } }
    case 'UPDATE_SALE_FINISH_QUALITY':
      return { ...state, saleProperty: { ...state.saleProperty, finishQuality: action.val } }
    case 'UPDATE_SALE_SPACE_TYPE':
      return { ...state, saleProperty: { ...state.saleProperty, spaceType: action.val } }
    case 'UPDATE_SALE_PARKING_SPACE':
      return { ...state, saleProperty: { ...state.saleProperty, parkingSpace: action.val } }
      
    case 'UPDATE_CLIENT_ADDRESS_IDENTICAL':
      return {
        ...state,
        saleProperty: { ...state.saleProperty, clientAddressIdentical: action.val }
      }
    case 'UPDATE_SALE_ADDRESS_ENTRY':
      return { ...state, saleProperty: { ...state.saleProperty, saleAddressEntry: action.val } }
    case 'UPDATE_SALE_ADDRESS_LIST':
      return { ...state, saleProperty: { ...state.saleProperty, saleAddressList: action.val } }
    case 'UPDATE_SALE_AUTO_POSTCODE':
      return { ...state, saleProperty: { ...state.saleProperty, saleAutoPostcode: action.val } }
    case 'UPDATE_SALE_AUTO_SELECT_ADDRESS':
      return {
        ...state,
        saleProperty: { ...state.saleProperty, saleAutoAddressSelectBox: action.val }
      }
    case 'UPDATE_SALE_POSTCODE':
      return { ...state, saleProperty: { ...state.saleProperty, salePostcode: action.val } }
    case 'UPDATE_SALE_ADDRESS_LINE_1':
      return { ...state, saleProperty: { ...state.saleProperty, saleAddressLine1: action.val } }
    case 'UPDATE_SALE_ADDRESS_LINE_2':
      return { ...state, saleProperty: { ...state.saleProperty, saleAddressLine2: action.val } }
    case 'UPDATE_SALE_ADDRESS_LINE_3':
      return { ...state, saleProperty: { ...state.saleProperty, saleAddressLine3: action.val } }

    case 'UPDATE_PURCHASE_PROPERTY_PRICE':
      return {
        ...state,
        purchaseProperty: {
          ...state.purchaseProperty,
          purchasePropertyPrice:
            String(action.val).split('').length <= 7 && +action.val <= 2000000
              ? action.val
              : state.purchaseProperty.purchasePropertyPrice
        }
      }
    case 'UPDATE_PURCHASE_OVER_PRICE_CHECK':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseOverPriceCheck: action.val }
      }
    case 'UPDATE_PURCHASE_OVER_PRICE_DETAIL':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseOverPriceDetail: action.val }
      }
    case 'UPDATE_PURCHASE_TYPE':
      return { ...state, purchaseProperty: { ...state.purchaseProperty, purchaseType: action.val } }
    case 'UPDATE_PURCHASE_LENDER_FEE':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseLenderFee: action.val }
      }
    case 'UPDATE_PURCHASE_LEASEHOLD_FEE':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseLeaseholdFee: action.val }
      }
    case 'UPDATE_PURCHASE_FT_FEE':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseFtFee: action.val }
      }
    case 'UPDATE_PURCHASE_NO_MOVE_NO_COSTS_FEE':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseNoMoveNoCostsFee: action.val }
      }
    case 'UPDATE_PURCHASE_MORTGAGE':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseMortgage: action.val }
      }
    case 'UPDATE_PURCHASE_LENDER_NOT_LIST':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseLenderNotList: action.val }
      }
    case 'UPDATE_PURCHASE_LENDER_NAME':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseLenderName: action.val }
      }
    case 'UPDATE_PURCHASE_LENDER_SELECTBOX':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseLenderSelectBox: action.val }
      }
    case 'UPDATE_PURCHASE_STAMP_DUTY_Q':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseStampDutyQ: action.val }
      }
    case 'UPDATE_PURCHASE_FIRST_TIME_BUYER':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseFirstTimeBuyer: action.val }
      }
    case 'UPDATE_PURCHASE_BUYING':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseBuying: action.val }
      }

    case 'UPDATE_PURCHASE_ADDRESS_CHECK':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseAddressCheck: action.val }
      }
    case 'UPDATE_PURCHASE_ADDRESS_ENTRY':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseAddressEntry: action.val }
      }
    case 'UPDATE_PURCHASE_ADDRESS_LIST':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseAddressList: action.val }
      }
    case 'UPDATE_PURCHASE_AUTO_POSTCODE':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseAutoPostcode: action.val }
      }
    case 'UPDATE_PURCHASE_AUTO_SELECT_ADDRESS':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseAutoAddressSelectBox: action.val }
      }
    case 'UPDATE_PURCHASE_POSTCODE':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchasePostcode: action.val }
      }
    case 'UPDATE_PURCHASE_ADDRESS_LINE_1':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseAddressLine1: action.val }
      }
    case 'UPDATE_PURCHASE_ADDRESS_LINE_2':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseAddressLine2: action.val }
      }
    case 'UPDATE_PURCHASE_ADDRESS_LINE_3':
      return {
        ...state,
        purchaseProperty: { ...state.purchaseProperty, purchaseAddressLine3: action.val }
      }

    case 'UPDATE_RESEND_TO_CLIENT':
      return { ...state, resendToClient: action.val }

    case 'UPDATE_AGREE':
      return { ...state, agree: action.val }
    case 'UPDATE_NO_MOVE_NO_COSTS':
      return { ...state, no_move_no_costs: action.val }
    case 'UPDATE_FAST_TRACK_TO_EXCHANGE':
      return { ...state, fast_track_to_exchange: action.val }

    default:
      return state
  }
}

export default quoteFormReducer
