// ** Initial State
const initialState = {
  data: [],
  loading: true
}

const managementCompanies = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MANAGEMENT_COMPANIES':
      return {
        ...state,
        data: action.data,
        loading: false
      }
    case 'CLEAR_MANAGEMENT_COMPANIES':
      return initialState
    case 'MANAGEMENT_COMPANIES_LOADING':
      return {
        ...state,
        loading: true
      }
    default:
      return state
  }
}

export default managementCompanies
