// ** Initial State
const initialState = {
  client1_title: '',
  client1_firstname: '',
  client1_lastname: '',
  client1_addressline_1: '',
  client1_addressline_2: '',
  client1_addressline_3: '',
  client1_address_postcode: '',

  client2_title: '',
  client2_firstname: '',
  client2_lastname: '',
  client2_auto_address_entry: '',
  client2_addressline_1: '',
  client2_addressline_2: '',
  client2_addressline_3: '',
  client2_address_postcode: '',

  solicitors: '',
  solicitors_addressline_1: '',
  solicitors_addressline_2: '',
  solicitors_addressline_3: '',
  solicitors_address_postcode: ''
}

const memoFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'MEMO_FORM_CLEAR':
      return initialState

    case 'UPDATE_CLIENT1_TITLE':
      return { ...state, client1_title: action.val }
    case 'UPDATE_CLIENT1_FIRSTNAME':
      return { ...state, client1_firstname: action.val }
    case 'UPDATE_CLIENT1_LASTNAME':
      return { ...state, client1_lastname: action.val }
    case 'UPDATE_CLIENT1_ADDRESSLINE_1':
      return { ...state, client1_addressline_1: action.val }
    case 'UPDATE_CLIENT1_ADDRESSLINE_2':
      return { ...state, client1_addressline_2: action.val }
    case 'UPDATE_CLIENT1_ADDRESSLINE_3':
      return { ...state, client1_addressline_3: action.val }
    case 'UPDATE_CLIENT1_ADDRESS_POSTCODE':
      return { ...state, client1_address_postcode: action.val }

    case 'UPDATE_CLIENT2_TITLE':
      return { ...state, client2_title: action.val }
    case 'UPDATE_CLIENT2_FIRSTNAME':
      return { ...state, client2_firstname: action.val }
    case 'UPDATE_CLIENT2_LASTNAME':
      return { ...state, client2_lastname: action.val }
    case 'UPDATE_CLIENT2_ADDRESS_ENTRY':
      return { ...state, client2_auto_address_entry: action.val }
    case 'UPDATE_CLIENT2_ADDRESSLINE_1':
      return { ...state, client2_addressline_1: action.val }
    case 'UPDATE_CLIENT2_ADDRESSLINE_2':
      return { ...state, client2_addressline_2: action.val }
    case 'UPDATE_CLIENT2_ADDRESSLINE_3':
      return { ...state, client2_addressline_3: action.val }
    case 'UPDATE_CLIENT2_ADDRESS_POSTCODE':
      return { ...state, client2_address_postcode: action.val }

    case 'UPDATE_SOLICITORS':
      return { ...state, solicitors: action.val }
    case 'UPDATE_SOLICITORS_ADDRESSLINE_1':
      return { ...state, solicitors_addressline_1: action.val }
    case 'UPDATE_SOLICITORS_ADDRESSLINE_2':
      return { ...state, solicitors_addressline_2: action.val }
    case 'UPDATE_SOLICITORS_ADDRESSLINE_3':
      return { ...state, solicitors_addressline_3: action.val }
    case 'UPDATE_SOLICITORS_ADDRESS_POSTCODE':
      return { ...state, solicitors_address_postcode: action.val }

    default:
      return state
  }
}

export default memoFormReducer
