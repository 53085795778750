// ** Initial State
const initialState = {
  excuse: '',
  excuse_detail: ''
}

const excuseFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'EXCUSE_FORM_CLEAR':
      return initialState

    case 'UPDATE_EXCUSE':
      return { ...state, excuse: action.val }
    case 'UPDATE_EXCUSE_DETAIL':
      return { ...state, excuse_detail: action.val }
  
    default:
      return state
  }
}

export default excuseFormReducer
