const constants = {
    SUBMIT_FEE_EARNER_MESSAGE_LOADING: 'SUBMIT_FEE_EARNER_MESSAGE_LOADING',
    SUBMIT_FEE_EARNER_MESSAGE_SUCCESS: 'SUBMIT_FEE_EARNER_MESSAGE_SUCCESS',
    SUBMIT_FEE_EARNER_MESSAGE_ERROR: 'SUBMIT_FEE_EARNER_MESSAGE_ERROR',
    SUBMIT_FEE_EARNER_MESSAGE_RESET: 'SUBMIT_FEE_EARNER_MESSAGE_RESET'
}

const initalState = {
    isLoading: false,
    error: null,
    isSubmitted: false
}

export default function submitFeeEarnerMessageReducer(state = initalState, action) {
    switch (action.type) {
        case constants.SUBMIT_FEE_EARNER_MESSAGE_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constants.SUBMIT_FEE_EARNER_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSubmitted: true
            }
        case constants.SUBMIT_FEE_EARNER_MESSAGE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case constants.SUBMIT_FEE_EARNER_MESSAGE_RESET:
            return {
                ...initalState
            }
        default:
            return state
    }
}
