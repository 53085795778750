// ** Redux Imports
import { combineReducers } from 'redux'
import agents from './agents'
// ** Reducers Imports
import auth from './auth'
import excuseForm from './excuseForm'
import feeEarners from './feeEarners'
import histories from './histories'
import landlords from './landlords'
import layout from './layout'
import lenders from './lenders'
import managementCompanies from './managementCompanies'
import memoForm from './memoForm'
import navbar from './navbar'
import negotiators from './negotiators'
import quote from './quote'
import quoteForm from './quoteForm'
import quotes from './quotes'
import reasons from './reasons'
import requestQuoteApp from './requestQuote'
import wishlistApp from './wishlist'
import zooplaAgents from './zooplaAgents'
import feeEarnerMessage from './feeEarnerMessage'
import validPhone from './validPhone'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  quoteForm,
  quote,
  quotes,
  agents,
  reasons,
  feeEarners,
  negotiators,
  zooplaAgents,
  memoForm,
  excuseForm,
  landlords,
  lenders,
  managementCompanies,
  histories,
  requestQuoteApp,
  wishlistApp,
  feeEarnerMessage,
  validPhone
})

export default rootReducer
