// ** Initial State
const initialState = {
  data: [],
  allCasesCount: 0,
  params: {},
  counts: {},
  url: '',
  loading: true,
  fetched:false
}

const quotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_QUOTES':
      return {
        ...state,
        data: action.data,
        allCasesCount: action.allCasesCount,
        params: action.params,
        url: action.url,
        loading: false,
        fetched: true
      }
    case 'CLEAR_QUOTES':
      return initialState
    case 'QUOTES_LOADING':
      return {
        ...state,
        loading: true
      }
    case 'QUOTES_COUNT':
      return {
        ...state,
        counts: action.val
      }
    default:
      return state
  }
}

export default quotesReducer
