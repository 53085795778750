// ** Initial State
const initialState = {
  data: [],
  loading: true
}

const reasonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_REASONS':
      return {
        ...state,
        data: action.data,
        loading: false
      }
    case 'CLEAR_REASONS':
      return initialState
    case 'REASONS_LOADING':
      return {
        ...state,
        loading: true
      }
    default:
      return state
  }
}

export default reasonsReducer
